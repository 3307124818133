import { useEffect, useState } from "react";
import { RecordInvoiceShow } from "../../modules/api/RecordInvoiceAPI";
import { useParams } from "react-router-dom";
import { StatusFormat } from "../../modules/widgets/form/StatusFormat";
import { ConvertTime2 } from "../../modules/widgets/form/ConvertTime";
import { Tab } from "@headlessui/react";
import AmountGraph from "./taps/AmountGraph";
import UnitGraph from "./taps/UnitGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";

const databaseIcon = <FontAwesomeIcon icon={faDatabase} />;

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const RecordInvoiceForm = () => {
  const param = useParams();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    id: null,
    name: "",
    area_number: "",
    water_number: "",
  });

  const getUser = (data: any, area_number: string, water_number: string) => {
    setUser({
      id: null,
      name: "",
      area_number: area_number,
      water_number: water_number,
    });
  };

  const getData = async () => {
    const area_number = param?.area_number || "";
    const water_number = param?.water_number || "";
    try {
      const res: any = await RecordInvoiceShow(area_number, water_number);
      setData(res.data.data || []);
      console.log(res.data.data);
      getUser(res.data.data, area_number, water_number);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      getData();
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="container pt-2">
          <div className="card mb-4">
            <div className="card-body">
              <div className="fs-5">{databaseIcon} ข้อมูลผู้ใช้น้ำ</div>
              <hr />
              <strong>เลขเขตที่:</strong> {user.area_number}{" "}
              <strong>เลขผู้ใช้น้ำ:</strong> {user.water_number}
            </div>
          </div>

          <div className="row g-1 bg-tab">
            <div className="col-md-12 mb-2">
              <Tab.Group>
                <Tab.List className="mb-2">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected ? "btn-tab-seleted me-2" : "btn-tab me-2"
                      )
                    }
                  >
                    จำนวนเงิน
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(selected ? "btn-tab-seleted" : "btn-tab")
                    }
                  >
                    จำนวนหน่วย
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <AmountGraph data={data} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <UnitGraph data={data}/>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
            <div className="col-md-12 mb-2">
              <div className="card">
                <div className="card-body">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>
                          <div className="bg-primary rounded-pill text-white">
                            รอบบิล
                          </div>
                        </th>
                        <th>
                          <div className="bg-primary rounded-pill text-white">
                            วันที่จดเลข
                          </div>
                        </th>
                        <th>
                          <div className="bg-primary rounded-pill text-white">
                            วันที่ชำระ
                          </div>
                        </th>
                        <th>
                          <div className="bg-primary rounded-pill text-white">
                            บาท
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((val: any, index: number) => {
                        return (
                          <tr key={`table_${index}`}>
                            <td>{val.issue_date_month_format}</td>
                            <td>
                              {val.water_meter_record ? ConvertTime2(val.water_meter_record.record_date) : '-'}
                            </td>
                            <td>
                              {StatusFormat(
                                val.status,
                                ConvertTime2(val.updated_at)
                              )}
                            </td>
                            <td>{val.total}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RecordInvoicePage = () => {
  return (
    <>
      <RecordInvoiceForm />
    </>
  );
};

export default RecordInvoicePage;
