import { ConvertTime2 } from "./ConvertTime";

const StatusFormat = (status: string, date: any) => {
  if (status === "pending") {
    return <div className="badge rounded-pill text-bg-warning">ยังไม่ชำระ</div>;
  } else if (status === "paymented") {
    return <div>{date}</div>;
  } else {
    return <div>{status}</div>;
  }
};

export { StatusFormat };
