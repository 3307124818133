import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API;

export const Record_Invoices = `${API_URL}/profile/record_invoices`;

export function RecordInvoiceShow(area_number: string, water_number: string) {
  return axios
    .get(Record_Invoices, {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Header": "*",
    //     "Content-Type": "application/json",
    //     "Accept": "*/*",
    //   },
      params: {
        area_number: area_number,
        water_number: water_number,
      },
    })
    .catch((err) => console.log(err));
}
