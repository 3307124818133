import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <>
      <div className="error-page">
        <div className="text-center">
          <h1 className="text-white">ไม่พบหน้านี้</h1>
          <Link to="/" className="btn btn-outline-info mt-4">
            กลับสู่หน้าหลัก
          </Link>
        </div>
      </div>
    </>
  );
};

export { Error404 };
