import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { ConvertMonthTH } from "../../../modules/widgets/form/ConvertTime";

const AmountGraph = ({ data }: any) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const getAmount = () => {
      let data_list: any = [];
      data.forEach((val: any) => {
        const existingData = data_list.find(
          (item: any) => item.month === val.issue_date
        );
        if (existingData) {
          existingData.total += val.total;
        } else {
          data_list.push({ month: val.issue_date, total: val.total });
        }
      });

      const months = data_list.map((val: any) => ConvertMonthTH(val.month));
      const amounts = data_list.map((val: any) => val.total);

      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const graph_data = {
        labels: months,
        datasets: [
          {
            label: "จำนวนเงิน",
            data: amounts,
            fill: false,
            borderColor: documentStyle.getPropertyValue("--blue-500"),
            tension: 0.4,
          },
        ],
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

      setChartData(graph_data);
      setChartOptions(options);
    };

    getAmount();
  }, [data]);

  return (
    <div className="card">
      <Chart
        type="line"
        data={chartData}
        options={chartOptions}
        style={{ height: "350px" }}
      />
    </div>
  );
};

export default AmountGraph;
