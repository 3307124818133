import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const HomeForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    navigate(`/pecord-invoice/${data.area_number}/${data.water_number}`);
  };

  return (
    <>
      <div className="center-screen p-2">
        <div className="card mb-3" style={{ maxWidth: "540px" }}>
          <div className="row g-0">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img src={process.env.PUBLIC_URL + "/images/logo-local.png"} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-2">
                    <label htmlFor="">เลขที่เขต</label>
                    <input
                      type="text"
                      className="form-control"
                      onInput={() => {}}
                      {...register("area_number", {
                        required: "กรุณากรอกเลขที่เขต",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="area_number"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="">เลขที่ผู้ใช้น้ำ</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("water_number", {
                        required: "กรุณากรอกเลขที่ผู้ใช้น้ำ",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="water_number"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                      ตรวจสอบข้อมูล
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const HomePage = () => {
  return (
    <>
      <HomeForm />
    </>
  );
};

export default HomePage;
