const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthTH = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "	พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

const addZero = (i: number) => {
  let index: string = "";
  if (i < 10) {
    index = "0" + i;
  } else {
    index = "" + i;
  }
  return index;
};

const ConvertTime = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())}&nbsp;{monthNames[current.getMonth()]}&nbsp;
        {current.getFullYear()}
      </div>
    );
  }
};

const ConvertTime2 = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())}/{addZero(current.getMonth())}/
        {current.getFullYear()}
      </div>
    );
  }
};

const ConvertMonthTH = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return "";
  } else {
    let current: any = new Date(time);
    return `${monthTH[current.getMonth()]}`;
  }
};

export { ConvertTime, ConvertTime2, ConvertMonthTH };
